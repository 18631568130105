








import Vue, { PropType } from "vue";
import { mapActions } from "vuex";
import _ from "lodash";

Object.defineProperty(Vue.prototype, "$_", { value: _ });

export default Vue.extend({
  name: "search-bar" as string,

  props: {
    usersSearch: Boolean as PropType<boolean>,
    questionsSearch: Boolean as PropType<boolean>,
    questionarySearch: Boolean as PropType<boolean>,
    audioSpotsSearch: Boolean as PropType<boolean>,
    audioCommercialsSearch: Boolean as PropType<boolean>,
    notificationSearch: Boolean as PropType<boolean>,
    billingSearch: Boolean as PropType<boolean>,
    payloadData: Object as PropType<object>,
    discountSearch: Boolean as PropType<boolean>,
    emailSearch:Boolean as PropType<boolean>,
    azInvoiceNumberSearch: Boolean as PropType<boolean>,
    purchaseOrderNumberSearch: Boolean as PropType<boolean>,
    venueSearchBar: Boolean as PropType<boolean>
  },

  data() {
    return {
      searchText: "",
    };
  },

  methods: {
    ...mapActions("venueModule", {
      setVenues: "FETCH_VENUES",
    }),
    ...mapActions("userModule", {
      setUsers: "FETCH_USERS",
    }),
    ...mapActions("venueQuestionaryModule", {
      setQuestionaryQuestions: "FETCH_QUESTIONARY_QUESTIONS",
      setVenueQuestionary: "FETCH_VENUE_QUESTIONARY",
    }),
    ...mapActions("audioCommercialsModule", {
      setAudioSpots: "FETCH_AUDIO_SPOTS",
    }),
    ...mapActions("audioCommercialsModule", {
      setAdvertCampaigns: "FETCH_ADVERT_CAMPAIGNS",
    }),
    ...mapActions("billingModule", {
      setInvoices: "FETCH_INVOICES",
    }),
    ...mapActions("azNotificationModule", {
      setNotifications: "FETCH_NOTIFICATIONS"
    }),
    ...mapActions("azPurchaseOrderModule", {
      setPurchaseOrderNumbers: "FETCH_PURCHASE_ORDER_NUMBERS"
    }),
    searchEntity: _.debounce(async function(): Promise<void> {
      if (this.usersSearch) {
        await this.setUsers(this.searchText);
        this.$emit("emitUsersImages");
      } else if (this.questionsSearch) {
        await this.setQuestionaryQuestions(this.searchText);
        this.$emit("resetPagination");
      } else if (this.questionarySearch) {
        await this.setVenueQuestionary({ payload: this.payloadData, name: this.searchText });
        this.$emit("resetPagination");
        this.$emit("search", this.searchText);
      } else if (this.audioSpotsSearch) {
        await this.setAudioSpots(this.searchText);
        this.$emit("resetPagination");
      } else if (this.audioCommercialsSearch) {
        await this.setAdvertCampaigns({ payload: this.payloadData, name: this.searchText });
        this.$emit("resetPagination");
        this.$emit("search", this.searchText);
      } else if (this.notificationSearch) {
        await this.setNotifications(this.searchText)
        this.$emit("resetPagination");
      } else if(this.emailSearch){
        this.$emit("emailSearch", this.searchText);
      } else if (this.discountSearch) {
        this.$emit("discountSearch", this.searchText);
      } else if (this.billingSearch) {
        await this.setInvoices({currentPage: '', invoiceNumber: this.searchText});
        this.$emit("resetPagination");
      } else if(this.azInvoiceNumberSearch){
        this.$emit("azInvoiceNumberSearch", this.searchText)
      } else if(this.purchaseOrderNumberSearch) {
        this.$emit('resetPagination')
        await this.setPurchaseOrderNumbers({pageNumber: 1, venueOwnerId: '$route.params.id', purchaseOrderNumber: this.searchText})
      } else if(this.venueSearchBar) {
        await this.setVenues({fetchRatings: true, name: this.searchText});
        this.$emit("emitVenuesImages");
      }
    }, 500),
  },
});
