































import Vue, {PropType} from "vue";
import {mapGetters} from "vuex";

export default Vue.extend({
  name: "user-access-rights-table-item",

  components: {
  },

  props: {
    company: Object as PropType<object>,
    hasPermissions: Boolean as PropType<boolean>,
    hasManageRolePermissions: Boolean as PropType<boolean>
  },

  computed: {
  },

  methods: {
    statusClass(status: number): string {
      if (status === 1) return "active";
      if (status === 2) return "deactivated";
    },
    statusName(value: number): string {
      if (value === 1) return this.$t("Active");
      if (value === 2) return this.$t("Deactivated");
    },
    typeClass(type: string): string {
      if (type === 'basic') return "basic";
      if (type === 'premium') return "premium";
    },
    typeName(value: string): string {
      if (value === 'basic') return this.$t("Basic");
      if (value === 'premium') return this.$t("Premium");
    },
  }
})
