





















































































































  import Vue from "vue";
  import Layout from "@/router/layouts/main.vue";
  import CzUsersSearchBar from "@/components/cz-users-search-bar.vue";
  import ListOfCompaniesTableItem from "@/components/companies/ListOfCompaniesTableItem.vue";
  import {mapActions, mapGetters} from "vuex";
  import filterCircleIcon from "@iconify-icons/ion/filter-circle";
  import vSelect from "vue-select";

  export default Vue.extend({
    components: {
      Layout,
      vSelect,
      "cz-users-search-bar": CzUsersSearchBar,
      "list-of-companies-table-item": ListOfCompaniesTableItem,
    },

    data() {
      return {
        title: "List of companies",
        currentPage: 1,
        perPage: 10,
        userAuthorisationsVenues: [],
        activeFilter: false,
        randomId: Math.floor(Math.random() * 100000),
        userIsOwnerOfVenueOwner: false,
        icons: {
          home: filterCircleIcon,
        },
        city:"",
        country:"",
        status:"",
        type:"",
        mockCompanies:[
    {
        "id": 1360,
        "name": "company 1",
        "address": "address 1",
        "country": "country 1",
        "type": "basic",
        "venues": 5,
        "active": 1,
        "created_at": 1629363712,
    },
    {
        "id": 1161,
        "name": "company 1",
        "address": "address 1",
        "country": "country 1",
        "type": "premium",
        "venues": 5,
        "active": 2,
        "created_at": 1627310808,
    }
]
      }
    },

    // async created(): Promise<void> {
    //   await this.checkLoggedUserRolePermission({ permissionId: 11 });
    //   await this.checkLoggedUserRolePermission({permissionId: 12});
    // },

    async mounted(): Promise<void> {

    },

    computed: {

    },

    methods: {
      goToNewCompany() {
        this.$router.push("/az-create-company")
      }
    },

  })
